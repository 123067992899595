import {Inject, Injectable} from '@angular/core';
import {MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService} from "@azure/msal-angular";
import {environment} from "../../environments/environment";
import {first, Subject, take, takeUntil} from "rxjs";
import {PublicClientApplication} from "@azure/msal-browser";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private readonly _destroying$: Subject<void> = new Subject<void>();

    userName: string = 'local-dev-user';

    constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, public authService: MsalService) {
        this.init();

        const accounts = this.authService.instance.getAllAccounts();
        if (accounts.length > 0) {
            const userAccount = accounts[0];
            this.userName = userAccount.username;
        }
    }

    init() {
        this.authService.initialize().pipe(first()).subscribe({
            next: (resp) => {
                console.log('authService initialized');
            },
            error: (err) => {
                console.error('authService initialization failed', err);
            }
        });
    }

    get isIframe() {
        return window !== window.parent && !window.opener;
    }

    destroy() {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    logOut() {
        this.authService.logoutRedirect({
            postLogoutRedirectUri: environment.auth.redirectUri
            // }).pipe(take(1)).subscribe({
            //   next: (resp) => {
            //     console.log('logoutPopup', resp);
            //     this.destroy();
            //   }
        });
    }
}
