<div class="dialog-container">
  <h1 mat-dialog-title><span class="dialog-title-text">{{title}}</span></h1>
  <div class="dialog-content" mat-dialog-content>
    {{message}}
  </div>
  <div class="dialog-actions" mat-dialog-actions>
    <button (click)="dialogRef.close(false)" color="warn" mat-button mat-dialog-close
            cdkFocusInitial>{{cancelText}}</button>
    <button (click)="dialogRef.close(true)" color="primary" mat-button mat-dialog-close>{{confirmText}}</button>
  </div>
</div>
