import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-basic-dialog',
    templateUrl: './basic-dialog.component.html',
    styleUrls: ['./basic-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule],
})
export class BasicDialogComponent {


  title: string = '';
  message: string = '';
  confirmText: string = '';
  cancelText: string = '';

  constructor(public dialogRef: MatDialogRef<BasicDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {
    dialogData: DialogData
  }) {
    this.title = this.data.dialogData.title;
    this.message = this.data.dialogData.message;
    this.confirmText = this.data.dialogData.confirmText;
    this.cancelText = this.data.dialogData.cancelText;
  }
}

export interface DialogData {
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
}
