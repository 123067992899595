import {enableProdMode} from '@angular/core';
import {environment} from './environments/environment';
import {AppComponent} from './app/app.component';
import {bootstrapApplication} from '@angular/platform-browser';
import {initializeApp} from "firebase/app";
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {appConfig} from "./app/app.config";


registerLocaleData(localeDe, 'de-DE', localeDeExtra);

// Firebase init
initializeApp(environment.firebaseConfig);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
  .catch(err => {
    console.error(err);
  });


