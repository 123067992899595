<div class="toolbar-container">
  <mat-toolbar class="toolbar mat-elevation-z2" color="primary" style="height: 4.5rem">
    <mat-toolbar-row>
      <span class="toolbar-logo"></span>
      @if (!detailView) {
        <div class="toolbar-title" routerLink="/landing-page">Wetter-Terminal<br>
        </div>
        <app-rfa-picker></app-rfa-picker>
      } @else {
        <button mat-button class="toolbar-return-button" aria-label="Zurück zur Übersicht"
                (click)="openReturnDialog()">
          <mat-icon class="toolbar-return-arrow" color="accent">keyboard_return</mat-icon>
          <span
            class="toolbar-return-text toolbar-return-button-animation">Zurück zum <b>{{ selectedRfaKuerzel?.toUpperCase() }}</b></span>
        </button>
      }
      <span class="spacer"></span>
      @if (!detailView) {
        <button [matTooltip]="loggedInUser() +' abmelden'" mat-stroked-button class="logout-button"
                aria-label="User logout Icon"
                (click)="logout()">
          <span class="logout-button-text">Abmelden</span>
          <mat-icon class="logout-icon" color="accent">logout</mat-icon>
        </button>
      } @else {
        <button *ngIf="detailView && showPublication" mat-stroked-button class="logout-button" aria-label="Publish Icon"
                (click)="showActions()">
          <span class="logout-button-text">Veröffentlichen</span>
          <mat-icon class="logout-icon" color="accent">publish</mat-icon>
        </button>
      }
    </mat-toolbar-row>
    <mat-toolbar-row class="subtitle-row">
      <span class="toolbar-subtitle contrast" *ngIf="!detailView">v. {{ APP_VERSION }}</span>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
