<div class="rfa-select-form-container">
  @if (rfaList$ | async; as rfaList) {
    @if (rfaList.length > 0) {
      <mat-form-field color="accent" floatLabel="always"
                      class="rfa-select-form"
                      appearance="fill">
        <mat-label style="margin-bottom: 10px">Auswahl</mat-label>
        @if (selectedRfa) {
          <mat-select [placeholder]="selectedRfa.kuerzel"
                      [value]="selectedRfa"
                      (selectionChange)="onRfaChange($event)" class="rfa-select">
            @for (rfa of rfaList; track rfa.kuerzel) {
              <mat-option *ngIf="!rfa.test" [value]="rfa">
                <span>{{ rfa?.kuerzel }}</span>
              </mat-option>
            }
          </mat-select>
        }
      </mat-form-field>
    } @else {
      <app-loading-spinner [style]="'margin-left: 25px; margin-top: 5px'" [size]="'small'"></app-loading-spinner>
    }
  }
</div>


