import {Component, ViewEncapsulation} from '@angular/core';
import {Observable} from "rxjs";
import {RundfunkAnstalt} from "../../../model/document.model";
import {Router} from "@angular/router";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import {RfaStore} from "../../../store/rfa/rfa.store";
import { LoadingSpinnerComponent } from '../loading/loading-spinner/loading-spinner.component';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import {P_DOCUMENTS} from "../../../app.routes";

@Component({
    selector: 'app-rfa-picker',
    templateUrl: './rfa-picker.component.html',
    styleUrls: ['./rfa-picker.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, LoadingSpinnerComponent, AsyncPipe]
})
export class RfaPickerComponent {

  private readonly selectedRfa$?: Observable<RundfunkAnstalt | undefined>;

  rfaList$: Observable<RundfunkAnstalt[]>;

  selectedRfa: RundfunkAnstalt | undefined;

  constructor(private router: Router, private rfaStore: RfaStore) {
    this.rfaList$ = this.rfaStore.getRfasFromStore();
    this.selectedRfa$ = this.rfaStore.getSelectedRfa();

    this.selectedRfa$.pipe().subscribe((selectedRfa) => {
      if (selectedRfa) {
        console.log('selectedRfa', selectedRfa);
        this.selectedRfa = selectedRfa;
      }
    });
  }

  onRfaChange($event: MatSelectChange) {
    const selectedRfa = $event.value as RundfunkAnstalt;
    this.rfaStore.setSelectedRfa(selectedRfa);
    this.navigateToDocumentList(selectedRfa);
  }

  private navigateToDocumentList(rfa: RundfunkAnstalt) {
    this.router.navigate([`${P_DOCUMENTS}/${rfa.kuerzel}`]);
  }
}
