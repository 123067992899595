import {Component, OnInit} from '@angular/core';
import {ChildrenOutletContexts, RouterOutlet} from "@angular/router";
import {AuthenticationService} from "./service/authentication.service";
import {slideInAnimation} from "./animations/animations";
import {NgIf} from '@angular/common';
import {ToolbarComponent} from './component/common/toolbar/toolbar.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [slideInAnimation],
    standalone: true,
    imports: [ToolbarComponent, NgIf, RouterOutlet]
})
export class AppComponent implements OnInit{

  constructor(private readonly authService: AuthenticationService, private contexts: ChildrenOutletContexts) {
  }

  isIframe = () => this.authService.isIframe;
  getRouteAnimationData = () => this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];

  ngOnInit(): void {
    this.authService.authService.handleRedirectObservable().subscribe();
  }
}
